import React from "react";

// reactstrap components
import { Row, Container, Col } from "reactstrap";

function Footer() {
  return (
    <footer className="footer footer-black footer-white">
      <Container>
        <Row>
          <Col lg="6" className=" text-center">
            <nav className="footer-nav">
              <ul>
                <li>
                  <a
                    href="/aviso-de-privacidad"
                    target="_blank"
                    rel="noopener"
                  >
                    Aviso de privacidad
                  </a>
                </li>
              </ul>
            </nav>
          </Col>
          <Col lg="6">
            <div className="credits ml-auto  text-center">
              <span className="copyright">
                Todos los derechos reservados © {new Date().getFullYear()}.
                Powered by Tenloo
                <img
                  className="brand-logo-tenloo"
                  style={{ marginTop: "0" }}
                  alt="logo-tenloo"
                  src={require("../../assets/img/favicon-tenloo.png")} />
              </span>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
