import React from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates strings
import classnames from "classnames";

// reactstrap components
import {
  Collapse,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Button,
  Row,
  Col
} from "reactstrap";

function MainNavbar() {
  // const [textColor, setTextColor] = React.useState("white");
  const [navbar2Color, setNavbar2Color] = React.useState("transparent");
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent2");
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 199 ||
        document.body.scrollTop > 199
      ) {
        // setTextColor("#333");
        setNavbarColor("");
        setNavbar2Color("white");
      } else if (
        document.documentElement.scrollTop < 200 ||
        document.body.scrollTop < 200
      ) {
        // setTextColor("white");
        setNavbarColor("navbar-transparent2");
        setNavbar2Color("transparent");
      }
    };


    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <div id="navbar" className={classnames("fixed-top-navbar")}>

      <div className={classnames("site-above-header")} style={{ background: `${navbar2Color}` }}
        color-on-scroll="300"
        expand="lg">
        <Container>
          <Row>
            <Col lg="2" expand="lg">
              <a className="" href="/">
                <img alt="Brand" className="img-logo" src={require("../../assets/img/logo-normal.webp")}></img>
              </a>
            </Col>
            <Col expand="lg" >
              <div style={{ float: "right" }}>
                <p className="text-navbar" style={{ display: "inline-block", marginRight: "25px", textAlign: "center" }}> Para Consultas </p>
                <Button
                  className="btn-call-to-action btn-round"
                  style={{ height: "auto", width: "auto", padding: "15px", marginTop: "25px", marginBottom: "25px" }}
                  color="primary"
                  href="/contacto"
                >
                  <i className="fa fa-solid fa-phone-volume"></i>
                  Llamános al +504 2270-7025 / +504 8819-7LAW
                </Button>
              </div>

            </Col>
          </Row>
        </Container>
      </div>

      <Navbar        
        className={classnames("new-navbar2", navbarColor)}
        style={{ height: "50px" }}
        color-on-scroll="300"
        expand="lg"
      >
        <Container>
          <div className="navbar-translate">
            <a className="brand navbar-brand" href="/">
              <img alt="Brand" className="img-brand" src={require("../../assets/img/logo-normal.webp")}></img>
            </a>
            <button
              aria-expanded={navbarCollapse}
              className={classnames("navbar-toggler navbar-toggler", {
                toggled: navbarCollapse
              })}
              onClick={toggleNavbarCollapse}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            navbar
            isOpen={navbarCollapse}
          >
            <Nav navbar className="navbar-nav2">
              <NavItem>
                <NavLink to="/" tag={Link}>
                  INICIO
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="/nosotros"
                  target=""
                >
                  NOSOTROS
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="/abogados"
                  target=""
                >
                  ABOGADOS
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="/areas-de-practica"
                  target=""
                >
                  ÁREAS DE PRÁCTICA
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="/contacto"
                  target=""
                >
                  CONTACTO
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>

        </Container>
      </Navbar>
    </div>
  );
}

export default MainNavbar;
