import React from "react";

// reactstrap components
import {
  Container
} from "reactstrap";
// core components

function PageHeader(props) {
  let pageHeader = React.createRef();
  // let image = "../../assets/img/image-page-header.png" + props.image;

  React.useEffect(() => {
    if (window.innerWidth < 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
    <>
      <div
        style={{
          backgroundImage:
            "url(" + require("../../assets/img/" + props.image) + ")",
          paddingTop: "25%"
        }}
        className="page-header page-header-xs"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <div className="row">
            <div className="col-sm">
              <div className="main-title title-page-header motto">
                <h1 className="presentation-title">
                  {props.title}
                </h1>
                <div>
                  <p className="description-white motto"><i className="fa fa-solid fa-house"></i> Inicio  <i className="fa fa-solid fa-arrow-right"></i>{props.title} </p>
                </div>
              </div>
            </div>
          </div>
        </Container>

        <div
          className="moving-clouds"
          style={{
            top: "0",
            backgroundImage: "url(" + require("../.././assets/img/clouds.png") + ")"
          }}
        />

        <div className="filter" />
      </div>
    </>
  );
}

export default PageHeader;
