import React from "react";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import MainNavbar from "../../components/Navbars/MainNavbar.js";
import LandingPageHeader from "../../components/Headers/HomePageHeader.js";
import Footer from "../../components/Footers/Footer.js";
import FloatButton from "../../components/FloatButton.js";
import CardService from "../../components/CardService.js";

function LandingPage() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  return (
    <>
      <MainNavbar />
      <LandingPageHeader />
      <div className="main">
        <div className="section text-center">
          <Container>
            <Row>
              <Col expand="lg" lg="12">
                <h2 className="title">Sobre Nosotros</h2>
                <h5 className="description">
                  La pasión por las leyes nace con el Abogado y Notario, José Tomás Guillén, quien
                  inspira a tres de sus cuatro hijos: José Oswaldo, Gerardo y Oscar, a estudiar la carrera
                  del Derecho.
                </h5>
                <br />
                {/* <p className="description-justify"> */}
                <h2 className="title">Nuestra misión</h2>
                {/* <p>Defender los intereses personales y económicos de nuestros clientes para que puedan alacanzar sus objetivos, con un asesoramiento transparente y comprensible y con la mayor confidencialidad que puedan encontrar en su vida personal, profesional y comercial.</p> */}
                <h5>Defender los intereses personales y económicos de nuestros clientes para que puedan alcanzar sus objetivos, con un asesoramiento transparente y comprensible y con la mayor confidencialidad que puedan encontrar en su vida personal, profesional y comercial.</h5>

                {/* </p> */}
                <br />
              </Col>
              <Col expand="lg" lg="12">
                <img
                  alt="attorney-guillen"
                  className="img-rounded img-responsive"
                  src={require("../../assets/img/about-us.webp")}
                />
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section2 text-center">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="12">
                <h2 className="title">¿Por qué elegirnos?</h2>
                {/* <h3>Experiencia, conocimiento y honestidad</h3> */}
                <br />
              </Col>
            </Row>
            <Row>
              <Col expand="lg">
                <i className="fa-why fa-solid fa-user-tie"></i>
                <h3 className="images-title">Experiencia</h3>
              </Col>
              <Col expand="lg">
                <i className="fa-why fa-solid fa-brain"></i>
                <h3 className="images-title">Conocimiento</h3>
              </Col>
              <Col expand="lg">
                <i className="fa-why fa-regular fa-hand"></i>
                <h3 className="images-title">Honestidad</h3>
              </Col>
            </Row>
          </Container>
        </div>
        <br />
        <div className="section2 text-center" >
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="12">
                <h2 className="title">Nuestros Clientes</h2>
              </Col>
            </Row>
          </Container>
        </div>
        <br />
        <div className="section2 text-center">
          <Container>
            <Row>
              <Col expand="lg">
                <div className="fill">
                  <img
                    alt=""
                    src={require("../../assets/img/logos-marcas.webp")} />
                </div>
                <p className="description"> <span>Sector de Energía, Banca y Finanzas, Hotelería y Turismo, Maquila, entre otros.</span></p>
                <br />
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section text-center" style={{ paddingTop: "0" }}>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="12">
                <h2 className="title">Especialistas en</h2>
              </Col>
            </Row>
            <Row>
              <CardService title="Derecho Corporativo" image="img1.webp" />
              <CardService title="Derecho Civil" image="img2.webp" />
              <CardService title="Derecho Tributario" image="img3.webp" />
            </Row>
            <br />
            <Row>
              <CardService title="Derecho Administrativo" image="img4.webp" />
              <CardService title="Derecho de Extranjería" image="img5.webp" />
              <CardService title="Derecho Notarial" image="img6.webp" />
            </Row>
            <br />
            <div className="btn-box">
              <Button
                href="/areas-de-practica"
                className="btn-round mr-1"
                color="primary"
                target=""
                outline
              >
                 {/* <i className="fa fa-solid fa-handshake"></i> */}
                 <i class="fa fa-solid fa-briefcase"></i>
                Ver más Información de las ÁREAS DE PRÁCTICA
              </Button>
            </div>
          </Container>
        </div>
      </div>
      <FloatButton />
      <Footer />
    </>
  );
}

export default LandingPage;
