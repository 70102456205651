import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

//styles
import "bootstrap/scss/bootstrap.scss";
import "./assets/scss/paper-kit.scss";
import "./assets/demo/demo.css";

//pages
import HomegPage from './views/pages/HomePage'
import AboutPage from './views/pages/AboutPage';
import AttorneyPage from './views/pages/AttorneyPage';
import ServicesPage from './views/pages/ServicesPage';
import ContactPage from './views/pages/ContactPage';
import LegalPage from './views/pages/LegalPage'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes >
      <Route
        path="/" element={<HomegPage />}
      />
      <Route
        path="/nosotros" element={<AboutPage />}
      />
      <Route
        path="/abogados" element={<AttorneyPage />}
      />
      <Route
        path="/areas-de-practica" element={<ServicesPage />}
      />
      <Route
        path="/contacto" element={<ContactPage />}
      />
       <Route
        path="/aviso-de-privacidad" element={<LegalPage />}
      />
      <Route
        path="*"
        element={<Navigate to="/" replace />}
      />
    </Routes >
  </BrowserRouter>
);
