import React from "react";

// reactstrap components
import { Button, Col, Container, Row } from "reactstrap";

// core components

function LandingPageHeader() {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth < 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
    <>
      <div
        style={{
          backgroundImage: "url(" + require("../../assets/img/background-attorneys-white.webp") + ")"
        }}
        className="page-header-home"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row>
            <Col expand="lg">
            </Col>
            <Col expand="lg">
              <div className="main-title motto text-center" >
                <h1 className="presentation-title-main">Guillén y Asociados</h1>
                <p className="description">Nos especializamos en brindar soluciones legales y notariales en Honduras y en el extranjero. Abogados, Notarios y Consultores altamente profesionales.</p>
                <br />
                <div className="btn-box">
                  <Button
                    href="/contacto"
                    className="btn-round mr-1"
                    color="primary"
                    target=""
                    outline
                  >
                    <i className="fa fa-solid fa-handshake"></i>
                    Contáctanos ahora
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>

      </div>
    </>
  );
}

export default LandingPageHeader;
