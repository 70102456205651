import React from "react";

import {
    Col
} from "reactstrap";

function CardService(props) {

    return (
        <Col expand="lg">
            <div
                style={{
                    backgroundImage:
                        "url(" + require("../assets/img/" + props.image) + ")"
                }}
                className="img-rounded container-service-img"
            >
                <h3 className="centered-text-title motto"> {props.title} </h3>
                <div className="filter" />                
            </div>
        </Col>
    );
}

export default CardService;
