import React from "react";

function FloatButton() {

    return (
        <div className="social-buttons-float">
            <a href="https://www.facebook.com/profile.php?id=100086494026057"
                target="_blank"
                rel="noreferrer"
                className="social-button social-button--facebook" aria-label="Facebook">
                <i className="fab fa-facebook-f"></i>
            </a>
            {/* <br /> */}
            <a href="https://api.whatsapp.com/send?phone=50488197529&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20sus%20servicios"
                target="_blank"
                rel="noreferrer"
                className="social-button social-button--whatsapp" aria-label="Snapchat">
                <i className="fab fa-whatsapp"></i>
            </a>
            {/* <br /> */}
            <a href="https://www.linkedin.com/in/guillen-y-asociados-4483b6252"
                target="_blank"
                rel="noreferrer"
                className="social-button social-button--linkedin" aria-label="LinkedIn">
                <i className="fab fa-linkedin-in"></i>
            </a>
        </div>
    );
}

export default FloatButton;
