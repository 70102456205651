import React, { useRef } from "react";
import emailjs from '@emailjs/browser';
import { useForm } from 'react-hook-form';

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import PageNavbar from "../../components/Navbars/PageNavbar.js";
import Footer from "../../components/Footers/Footer.js";
import PageHeader from "../../components/Headers/PageHeader.js";
import FloatButton from "../../components/FloatButton.js";


function ContactPage() {

  const [messageSubmit, setMessageSubmit] = React.useState("");
  const [messageColor, setMessageColor] = React.useState("");
  const form = useRef();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    trigger,
  } = useForm();


  const onSubmit = (data) => {
    data.preventDefault();

    emailjs.sendForm('guillenyasociados', 'template-guillen-mail', form.current, '4jRDM71fqmgL0q-Zg')
      .then((result) => {
        setMessageColor("badge-success");
        setMessageSubmit("Su mensaje ha sido enviado");
      }, (error) => {
        setMessageColor("badge");
        setMessageSubmit("Su mensaje no ha podido ser enviado");
      });

    reset();

  };


  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });



  return (
    <>
      <PageNavbar />
      <PageHeader title="Contacto" image="background-form.webp" />
      <div className="main">
        <div className="section text-center">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="12">
                <h2 className="title">¿Está preparado para obtener respuestas de un abogado calificado?</h2>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section">
          <Container>
            <Row>
              <Col className="mx-auto" lg="6">
                <div className="info-wrap">
                  <h3 className="title mb-4 mt-md-4 motto">Información de Contacto</h3>
                  <div className="dbox w-100 d-flex align-items-start">
                    <div className="icon d-flex align-items-center justify-content-center">
                      <i className="fa-solid fa-location-dot"></i>
                    </div>
                    <div className="text pl-3">
                      <p><span>Dirección:</span> <a target="_blank" rel="noreferrer" href="https://goo.gl/maps/qfzbrqpsyA9BWaXc6">Torre Metrópolis, Piso 15,
                        Suite 11507, Tegucigalpa, Honduras</a>
                      </p>
                    </div>
                  </div>
                  <div className="dbox w-100 d-flex align-items-center">
                    <div className="icon d-flex align-items-center justify-content-center">
                      <i className="fa-solid fa-phone"></i>
                    </div>
                    <div className="text pl-3">
                      <p><span>Teléfono:</span> <a href="tel://2270-7025">2270-7025 / 2270-7026</a></p>
                    </div>
                  </div>
                  <div className="dbox w-100 d-flex align-items-center">
                    <div className="icon d-flex align-items-center justify-content-center">
                      <i className="fa-solid fa-brands fa-whatsapp"></i>
                    </div>
                    <div className="text pl-3">
                      <p><span>WhatsApp:</span> <a href="https://api.whatsapp.com/send?phone=50488197529&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20sus%20servicios">
                        +504 8819-7529</a></p>
                    </div>
                  </div>
                  <div className="dbox w-100 d-flex align-items-center">
                    <div className="icon d-flex align-items-center justify-content-center">
                      <i className="fa-solid fa-envelope"></i>
                    </div>
                    <div className="text pl-3">
                      <p><span>Correo:</span> <a href="mailto:info@guillenyasociados.com">info@guillenyasociados.com</a></p>
                    </div>
                  </div>
                  <div className="dbox w-100 d-flex align-items-center">
                    <div className="icon d-flex align-items-center justify-content-center">
                      <i className="fa-solid fa-clock"></i>
                    </div>
                    <div className="text pl-3">
                      <p><span>Horario de Atención :</span> <br />
                        <a href="#Horario">Lunes a Viernes de 8:30 a.m. a 5:00 p.m.</a>
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="card-contact-us" lg="6">
                <form ref={form} onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group">
                    <label className="col-form-label">Nombre:</label>
                    <input
                      type="text"
                      placeholder="Nombre"
                      className={`form-control ${errors.name && "invalid"}`}
                      {...register("name", { required: "El campo de Nombre es requerido" })}
                      onKeyUp={() => {
                        trigger("name");
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label className="col-form-label">Correo:</label>
                    <input
                      type="text"
                      placeholder="email@ejemplo.com"
                      className={`form-control ${errors.email && "invalid"}`}
                      {...register("email", {
                        required: "El Campo de correo es requerido",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Correo invalido",
                        }
                      })}
                      onKeyUp={() => {
                        trigger("email");
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label className="col-form-label">Asunto:</label>
                    <input
                      type="text"
                      placeholder="Asunto"
                      className={`form-control ${errors.subject && "invalid"}`}
                      {...register("subject", { required: "El campo de asunto es requerido" })}
                      onKeyUp={() => {
                        trigger("subject");
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label className="col-form-label">Mensaje:</label>
                    <textarea
                      rows="4"
                      placeholder="Escribe algo (mínimo 10 carácteres)"
                      className={`form-control ${errors.message && "invalid"}`}
                      {...register("message", {
                        required: "El campo de mensaje es requerido",
                        minLength: {
                          value: 10,
                          message: "Minimum Required length is 10",
                        },
                        maxLength: {
                          value: 50,
                          message: "Maximum allowed length is 50 ",
                        }
                      })}
                      onKeyUp={() => {
                        trigger("message");
                      }}
                    ></textarea>
                  </div>
                  <div className="col text-center">
                    <Button
                      className="btn-round"
                      outline
                      color="neutral"
                      size="lg"
                      type="submit"
                      value="Send message"
                    >
                      <i className="fa fa-sharp fa-solid fa-paper-plane"></i>
                      Enviar
                    </Button>

                  </div>
                  <div className="col-12 text-center">
                    <br />
                    <p className={messageColor}>
                      {errors.message && (
                        <span className="badge" >Porfavor rellene todos los campos</span>
                      )}
                      {messageSubmit}
                    </p>
                  </div>
                </form>
              </Col>
            </Row>
          </Container>
        </div>
        <div>
          <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d483.73222192988203!2d-87.18637235901257!3d14.085571821016766!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f6fa2d4915e97ef%3A0x57af63058677bee9!2sTorre%20Metr%C3%B3polis!5e0!3m2!1ses-419!2shn!4v1666367172365!5m2!1ses-419!2shn"
            style={{ width: "100%", height: "50vh" }}
            // allowFullScreen="" 
            loading="lazy"
          // referrerPolicy="no-referrer-when-downgrade"
          >
          </iframe>
        </div>

      </div>
      <FloatButton />
      <Footer />
    </>
  );
}

export default ContactPage;
