import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import PageNavbar from "../../components/Navbars/PageNavbar.js";
import Footer from "../../components/Footers/Footer.js";
import PageHeader from "../../components/Headers/PageHeader.js";
// import CardService from "../../components/CardService.js";
import FloatButton from "../../components/FloatButton.js";

function ServicesPage() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  return (
    <>
      <PageNavbar />
      <PageHeader title="Áreas de Práctica" image="services.webp" />
      <div className="main">
        <div className="section text-center">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="12">
                {/* <h2 className="title">Nuestros Abogados</h2> */}
                <p>
                  Nos especializamos en brindar soluciones legales y notariales en Honduras y en el extranjero.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section text-center" style={{ paddingTop: "0" }}>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-left" md="12">
                <h5>Derecho Corporativo</h5>
                <ul>
                  <li><p className="description-justify">Constitución de sociedades, empresas y fideicomisos</p></li>
                  <li><p className="description-justify">Elaboración y negociación de contratos, convenios y acuerdos mercantiles</p></li>
                  <li><p className="description-justify">Asesoría y consultoría sobre la conducción jurídica de una empresa mercantil y de las obligaciones y derechos que las mismas tienen</p></li>
                </ul>
                <br />
                <h5>Derecho Civil</h5>
                <ul>
                  <li><p className="description-justify">Elaboración y negociación de todo tipo contratos, convenios y acuerdos</p></li>
                  <li><p className="description-justify">Representaciones en procesos judiciales ante los Tribunales de la República</p></li>
                  <li><p className="description-justify">Planificación sucesorial, herencias y testamentos</p></li>
                  <li><p className="description-justify">Reclamaciones para la indemnización de daños y perjuicios</p></li>
                </ul>
                <br />
                <h5>Derecho Tributario</h5>
                <ul>
                  <li><p className="description-justify">Diligencias ante el Servicio de Administración de Rentas (SAR) y la Administración Aduanera de Honduras (ADUANAS), para gestionar notas de crédito, devoluciones, correcciones e impugnaciones tributarios y aduaneros, entre otros•	Diligencias ante el Servicio de Administración de Rentas (SAR) y la Administración Aduanera de Honduras (ADUANAS), para gestionar notas de crédito, devoluciones, correcciones e impugnaciones tributarios y aduaneros, entre otros</p></li>
                  <li><p className="description-justify">Obtención de exoneraciones tributarias y aduaneras ante la Secretaria de Finanzas y otras oficinas gubernamentales</p></li>
                  <li><p className="description-justify">Representaciones en procesos judiciales en la materia fiscal ante los Tribunales de la jurisdicción de lo Contencioso Administrativo</p></li>
                  <li><p className="description-justify">Asesoría y consultoría tributaria y aduanera preventiva para empresas nacionales e internacionales; así mismo, asesoría y consultoría en los procesos de fiscalización que realiza el SAR y ADUANAS a los obligados tributarios</p></li>
                </ul>
                <br />
                <h5>Derecho Administrativo</h5>
                <ul>
                  <li><p className="description-justify">Solicitudes y gestiones ante las dependencias del Poder Ejecutivo y las Municipalidades</p></li>
                  <li><p className="description-justify">Impugnación de actos emitidos por la Administración Pública ante los Tribunales de la República</p></li>
                  <li><p className="description-justify">Reclamos administrativos de tipo económico previo a la acción judicial ante los Tribunales de Justicia</p></li>
                </ul>
                <br />
                <h5>Derecho Notarial</h5>
                <ul>
                  <li><p className="description-justify">La autorización de poderes, compraventa de bienes inmuebles y bienes muebles y cualquier acto o contrato que requiera de la emisión de una escritura pública</p></li>
                  <li><p className="description-justify">La autorización de testamentos, fideicomisos y otros instrumentos de planificación patrimonial</p></li>
                  <li><p className="description-justify">La autorización de asuntos no contenciosos, como ser: divorcios por mutuo consentimiento, separaciones de hecho, ejecución de garantías, matrimonios, informaciones ad-perpetuam, entre otros *La autorización de cualquier escritura pública es posible hacerla en Honduras y en el extranjero, de conformidad con las atribuciones que el código del notariado nos concede a los notarios</p></li>
                </ul>
                <br />
                <h5>Derecho de Extranjería </h5>
                <ul>
                  <li><p className="description-justify">Solicitudes para la obtención de permisos especiales de permanencia hasta por 5 años </p></li>
                  <li><p className="description-justify">Solicitudes para la obtención de residencias</p></li>
                  <li><p className="description-justify">Solicitud para la obtención de la nacionalidad hondureña</p></li>
                  <li><p className="description-justify">Solicitud para la aplicación de los beneficios y exoneraciones tributarias para el hondureño ausente</p></li>
                </ul>
              </Col>
            </Row>
            <br />
          </Container>
        </div>
      </div>
      <FloatButton />
      <Footer />
    </>
  );
}

export default ServicesPage;
