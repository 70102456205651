import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import PageNavbar from "../../components/Navbars/PageNavbar.js";
import Footer from "../../components/Footers/Footer.js";
import PageHeader from "../../components/Headers/PageHeader.js";
import FloatButton from "../../components/FloatButton.js";

function AttorneyPage() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  return (
    <>
      <PageNavbar />
      <PageHeader title="Abogados" image="abogados.webp" />
      <div className="main">
        <div className="section text-center">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="12">
                <h2 className="title">Nuestros Abogados</h2>
                <p>
                  Contamos con los mejores abogados y alianzas estratégicas que nos permiten brindarte un servicio de calidad y eficiencia.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        <div
          className="section"
          style={{ paddingTop: "0" }}
        >
          <Container>
            <Row>
              <Col expand="lg" lg="6">
                <h3 className="title mx-auto">Abogado José Tomás Guillén</h3>
                <p className="description-justify">
                  Ejerce el derecho desde 1978 después de obtener el título de Abogado de la
                  Universidad Nacional Autónoma de Honduras, UNAH. Misma institución de la cual fue
                  Vicerrector en 1990. En el año 1985 obtiene el Exequátur de Notario por la Corte
                  Suprema de Justicia y asume el cargo de Secretario de la Facultad de Derecho.
                  José Tomás Guillén tiene una amplia experiencia en la enseñanza de las asignaturas
                  del Derecho Procesal Civil, Civil y Mercantil. Sin embargo, su más amplio recorrido ha
                  sido en los Tribunales de la República.
                  Por su conocimiento y trayectoria, el Abogado José Tomás Guillén fue llamado para
                  participar en el equipo de profesionales del derecho que redactó el actual código procesal Civil que entró en vigencia en 2010.
                </p>
              </Col>
              <Col expand="lg" lg="6">
                <img
                  alt="attorney-guillen"
                  className="img-rounded img-responsive"
                  src={require("../../assets/img/attorney-1.webp")}
                />
                <div className="social-buttons-attorney">
                  <a
                    href="https://www.linkedin.com/in/jos%C3%A9-oswaldo-guill%C3%A9n-b95162234?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BkSKPeenHTN%2BB4rgwGxkc0g%3D%3D"
                    target="_blank"
                    rel="noreferrer"
                    className="social-button social-button--linkedin" aria-label="LinkedIn">
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </div>
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col expand="lg" lg="6">
                <img
                  alt="attorney-guillen"
                  className="img-rounded img-responsive"
                  src={require("../../assets/img/attorney-2.webp")}
                />
                <div className="social-buttons-attorney">
                  <a href="https://www.linkedin.com/in/guillen-y-asociados-4483b6252"
                    className="social-button social-button--linkedin"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="LinkedIn">
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </div>
              </Col>
              <Col expand="lg" lg="6">
                <h3 className="title mx-auto">Abogado José Oswaldo Guillén Domínguez</h3>
                <p className="description-justify">
                  Ejerce el derecho desde 1999 después de obtener el título de Abogado de la
                  Universidad Nacional Autónoma de Honduras, UNAH. Cuenta con estudios de
                  postgrado en Derecho Internacional y Relaciones Internacionales de la Universidad de
                  Barcelona y una Maestría en Fiscalidad Internacional de la Universidad Internacional
                  de La Rioja.
                  Posteriormente recibe el Exequátur de Notario y su ramo de conocimientos ha sido en
                  el Derecho Corporativo, Derecho Notarial, Derecho de la Extranjería, Derecho
                  Administrativo y Derecho Tributario.
                  En el sector público ocupó cargos como Secretario General de la Secretaria de
                  Gobernación y Justicia, Ministro Director de la Dirección Ejecutiva de Ingresos (ahora
                  SAR) y fue el Asesor Legal del Ex Presidente de la República, Lic. Ricardo Maduro
                  Joest. En el sector privado se desempeña como asesor y consultor de empresas
                  nacionales e internacionales, en diversos rubros de importancia económica para el
                  país.
                  El Abogado José Oswaldo Guillén cuenta con más de 21 años de experiencia, tanto
                  en el sector privado, como en el sector público, lo que le permite tener una visión
                  amplia de las necesidades de cada cliente y sus proyectos.
                  Ha sido redactor y co-redactor de diversas leyes, reglamentos y normativa ejecutiva y
                  municipal sobre distintos temas relacionados con la materia tributaria, de extranjería,
                  financiera y administrativa.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        <div
          className="section section-image"
          style={{
            backgroundImage: "url(" + require("../../assets/img/nuestros-abogados.webp") + ")"
          }}
        ></div>
      </div>
      <FloatButton />
      <Footer />
    </>
  );
}

export default AttorneyPage;
