import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import PageNavbar from "../../components/Navbars/PageNavbar.js";
import Footer from "../../components/Footers/Footer.js";
import PageHeader from "../../components/Headers/PageHeader.js";
import FloatButton from "../../components/FloatButton.js";

function AboutPage() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  return (
    <>
      <PageNavbar />
      <PageHeader title="Nosotros" image="image-page-header.webp" />
      <div className="main">
        <div className="section text-center">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="12">
                <h2 className="title">Nuestra Historia</h2>
                <p>
                  El Bufete Guillén y Asociados se fundó en el año 1999 y desde entonces provee
                  asesoría y consultoría legal en Derecho Corporativo, Derecho Tributario, Derecho
                  Civil, Derecho Notarial y de Extranjería.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section text-center" style={{ paddingTop: "0" }}>
          <Container>
            <Row>
              <Col lg="8" className="mx-auto">
                <h5 >
                  La pasión por las leyes nace con el Abogado y Notario, José Tomás Guillén, quien
                  inspira a tres de sus cuatro hijos: José Oswaldo, Gerardo y Oscar, a estudiar la carrera
                  del Derecho.
                </h5>
              </Col>
            </Row>
          </Container>
        </div>
        <div
          className="section section-image2 section-image-text"
          style={{
            backgroundImage: "url(" + require("../../assets/img/personal.webp") + ")"
          }}
        >
          <Container>
            <Row>
              <Col lg="12" md="12">
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section text-center">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="12">
                <h2 className="title">Nuestros Valores</h2>
              </Col>
            </Row>
            <Row>
              <Col expand="lg" lg="4">
                <img
                  alt="..."
                  className="img-no-padding img-valores"
                  src={require("../../assets/img/icon-confianza.png")}
                />
                <h5 className="images-title">Confianza</h5>
              </Col>
              <Col expand="lg" lg="4">
                <img
                  alt="..."
                  className="img-no-padding img-valores"
                  src={require("../../assets/img/icon-lealtad.png")}
                />
                <h5 className="images-title">Lealtad</h5>
              </Col>
              <Col expand="lg" lg="4">
                <img
                  alt="..."
                  className="img-no-padding img-valores"
                  src={require("../../assets/img/icon-conocimiento.png")}
                />
                <h5 className="images-title">Conocimiento</h5>
              </Col>
            </Row>
            <Row>
              <Col expand="lg" lg="4">
                <img
                  alt="..."
                  className="img-no-padding img-valores"
                  src={require("../../assets/img/icon-honestidad.png")}
                />
                <h5 className="images-title">Honestidad</h5>
              </Col>
              <Col expand="lg" lg="4">
                <img
                  alt="..."
                  className="img-no-padding img-valores"
                  src={require("../../assets/img/icon-secreto-profesional.png")}
                />
                <h5 className="images-title">Secreto Profesional</h5>
              </Col>
              <Col expand="lg" lg="4">
                <img
                  alt="..."
                  className="img-no-padding img-valores"
                  src={require("../../assets/img/icon-diligencia.png")}
                />
                <h5 className="images-title">Diligencia</h5>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section text-center">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="12">
                <h2 className="title">Misión</h2>
                <p>
                  Defender los intereses personales y económicos de nuestros clientes para que puedan
                  alcanzar sus objetivos, con un asesoramiento transparente y comprensible y con la mayor
                  confidencialidad que puedan encontrar en su vida personal, profesional y comercial.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section text-center">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="12">
                <h2 className="title">Visión</h2>
                <p>
                  Ser un estudio jurídico líder en la región, consolidando su crecimiento con experiencia y
                  eficiencia profesional, ofreciendo un servicio integral de calidad en asesoría y consultoría
                  legal con reconocido prestigio por sus valores y eficacia demostrada.
                </p>
              </Col>
            </Row>
          </Container>
        </div>






        <div className="section text-center">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="12">
                <blockquote className="blockquote">
                  <p className="mb-0">
                    Por supuesto que la gente se está haciendo inteligente; están dejando que sus abogados, en lugar de sus conciencias, sean sus guías.
                  </p>
                  <br />
                  <footer className="blockquote-footer">
                    {" "}
                    <cite title="source Title">Will Rogers</cite>
                  </footer>
                </blockquote>
                {/* <p className="quote">
                  Por supuesto que la gente se está haciendo inteligente; están dejando que sus abogados, en lugar de sus conciencias, sean sus guías. – Will Rogers
                </p> */}
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <FloatButton />
      <Footer />
    </>
  );
}

export default AboutPage;
